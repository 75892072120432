.detail-gallery {
  position: relative;
}

.zoom-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
}

.product-image-slider .slick-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-image-slider figure {
  margin: 0;
}

.slider-nav-thumbnails {
  margin-top: 10px;
}

.slider-nav-thumbnails .slick-slide {
  cursor: pointer;
}


/* Main container for variations */
.variation-container {
  max-width: 600px;
  margin: 0 auto;
}

/* Option Item: Ensures spacing and proper alignment */
.option-item {
  display: inline-block;
  margin: 5px;
  text-align: center;
}

/* Button Styling: Make the buttons consistent and appealing */
.option-button {
  padding: 5px 7px;
  border: 2px solid #ddd;
  border-radius: 5px;
  background-color: #ffffff;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
  min-width: 80px;
}

/* Hover effect for buttons */
.option-button:hover {
  background-color: #e7e7e7;
  border-color: #ccc;
}

/* Selected Button Styling: Add color when selected */
.option-button.selected {
  background-color: #fefff1;
  border-color: #3BB77E;
  color: #000;
}

/* Color Label for color variations */
.color-label {
  display: block;
  margin-top: 8px;
  font-size: 0.9rem;
  color: #333;
}

/* Price Display Styling */
.price-display {
  margin-top: 20px;
  padding: 5px;
  background-color: #f1f1f1;
  font-size: 1.2rem;
  font-weight: bold;
  border: 2px solid #ccc;
  border-radius: 5px;
  text-align: center;
}
